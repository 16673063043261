<template>
  <b-button
    block
    variant="light"
    class="google-button"
    :disabled="disabled"
    @click="onClick"
  >
    <LoadingSpinner :is-loaded="isLoaded" small>
      <img class="google-logo" src="/logos/google.svg" />
      {{ label }}
    </LoadingSpinner>
  </b-button>
</template>

<script>
import LoadingSpinner from "../../utils/LoadingSpinner.vue";

export default {
  name: "GoogleButton",
  components: {
    LoadingSpinner,
  },
  mixins: [],
  props: {
    isLoaded: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
};
</script>

<style scoped>
.google-button,
.google-button:hover,
.google-button:active,
.google-button:focus {
  padding: 8px;
  color: #757575;
  border-color: #c5c5c5;
  font-weight: 500;
  font-size: 14px;
}

.google-logo {
  height: 18px;
  margin-right: 16px;
}
</style>
