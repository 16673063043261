import { render, staticRenderFns } from "./FormCheckbox.vue?vue&type=template&id=e382f2b4&scoped=true&"
import script from "./FormCheckbox.vue?vue&type=script&lang=js&"
export * from "./FormCheckbox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e382f2b4",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormCheckbox} from 'bootstrap-vue'
    installComponents(component, {BFormCheckbox})
    

export default component.exports