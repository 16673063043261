<template>
  <b-navbar variant="white" class="main-navbar border-bottom" sticky>
    <b-button
      class="d-lg-none mr-1"
      variant="outline-dark"
      @click="showMobileSidebar = true"
    >
      <font-awesome-icon :icon="['fas', 'bars']" />
    </b-button>
    <b-sidebar
      v-model="showMobileSidebar"
      sidebar-class="border-right"
      no-header
      backdrop
    >
      <SideMenu
        :collapsed="false"
        @toggle-collapsed="showMobileSidebar = false"
      />
    </b-sidebar>

    <b-navbar-brand :to="{ name: 'home' }" class="m-2">
      <b-img
        :src="$root.seminary.logo"
        :alt="$root.seminary.title"
        class="seminary-logo"
        center
      />
    </b-navbar-brand>

    <b-navbar-nav class="ml-auto">
      <template v-if="$root.isAuthenticated">
        <b-button
          key="navbar-profile-button"
          :to="{ name: 'profile-detail' }"
          variant="outline-dark"
          class="m-1"
        >
          <font-awesome-icon :icon="['fas', 'user-circle']" />
          <span class="d-none d-md-inline">
            {{ $root.state.user === null ? "" : $root.state.user.full_name }}
          </span>
        </b-button>
        <b-button
          key="navbar-logout-button"
          variant="outline-dark"
          class="m-1"
          @click="logout"
        >
          <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
          <span class="d-none d-md-inline"> Odhlásiť sa </span>
        </b-button>
      </template>
      <b-button
        v-else
        key="navbar-login-button"
        v-b-modal.login-modal
        variant="outline-dark"
        class="m-1"
      >
        <font-awesome-icon :icon="['fas', 'sign-in-alt']" />
        <span class="d-none d-md-inline"> Prihlásiť sa </span>
      </b-button>

      <b-button
        v-for="other in $root.seminary.otherSeminary"
        :key="other.title"
        :href="other.url"
        :variant="other.variant"
        class="m-1 d-none d-lg-block"
      >
        {{ other.title }}
      </b-button>

      <b-button
        href="https://p-mat.sk"
        variant="primary"
        class="m-1 d-none d-lg-block py-0 px-1"
      >
        <b-img
          src="/logos/pmat-horizontal-white.svg"
          alt="P-MAT"
          class="base-logo"
        />
      </b-button>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { SideMenu } from "frontend-common";

export default {
  name: "App",
  components: {
    SideMenu,
  },
  mixins: [],
  props: {},
  data() {
    return {
      showMobileSidebar: false,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        // When parameter openLogin is passed to the navigation,
        // open the login modal
        if (route.params.openLogin) {
          this.$nextTick(() => {
            this.$bvModal.show("login-modal");
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.$root.unsetAuthToken();
      this.$root.successToast("Odhlásenie úspešné!");
    },
  },
};
</script>

<style scoped>
.seminary-logo {
  max-height: 38px;
  max-width: 45vw;
  width: 100%;
}

.other-logo {
  height: 1em;
  margin-top: -2px; /* bad vertical centering of the svg inside button link */
}

.base-logo {
  height: 2em;
}
</style>
