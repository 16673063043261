<template>
  <div>
    <b-carousel controls indicators>
      <b-link
        v-for="(slide, index) in $root.seminary.slides"
        :key="`slide-${index}`"
        :to="slide.route"
      >
        <b-carousel-slide>
          <template v-slot:img>
            <b-img
              class="d-none d-md-block img-fluid w-100"
              :src="slide.desktop"
            />
            <b-img
              class="d-block d-md-none img-fluid w-100"
              :src="slide.mobile"
            />
          </template>
        </b-carousel-slide>
      </b-link>
    </b-carousel>
    <b-jumbotron
      v-if="$root.storage.firstTimeVisitor"
      bg-variant="secondary"
      class="m-0 pt-4 pb-0 pl-5 pr-5 welcome-banner"
    >
      <b-container class="text-center text-white font-weight-bold">
        <b-row align-v="center" class="text-white">
          <b-col cols="12" md="8">
            <h1>
              Vitaj na stránkach {{ $root.seminary.titleCases.genitive }}!
            </h1>
            <h3>Zdá sa, že si tu prvýkrát...</h3>
            <div class="mt-4 text-left">
              <small>
                Na zlepšenie našich služieb používame cookies. Pokračovaním v
                prehliadaní tejto stránky súhlasíte s ich použitím. Viac
                informácií o spracovaní osobných údajov nájdete vo
                <b-link
                  class="text-dark"
                  :to="{ name: 'page', params: { pageSlug: 'gdpr' } }"
                  >vyhlásení o spracovaní osobných údajov</b-link
                >.
              </small>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <b-button
              variant="primary"
              class="text-white border m-3 p-3 text-nowrap"
              @click="firstTimeVisit()"
            >
              <font-awesome-icon :icon="['fas', 'user-friends']" size="7x" />
              <h5>Ako sa zapojiť?</h5>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
      <b-button
        class="text-light close-button"
        @click="$root.storage.firstTimeVisitor = false"
      >
        <font-awesome-icon size="2x" :icon="['fas', 'times']" />
      </b-button>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: "HomeCarousel",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    firstTimeVisit() {
      this.$root.storage.firstTimeVisitor = false;
      this.$router.push({
        name: "page",
        params: { pageSlug: "seminar" },
      });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 320%;
}

a:hover {
  text-decoration: none;
  color: white;
}

.welcome-banner {
  position: relative;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
