import constants from "../constants";

export default {
  methods: {
    apiPosts(page = 1) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `cms/posts/?page=${page}&page_size=3`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
  },
};
