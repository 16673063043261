import constants from "../constants";

export default {
  methods: {
    apiRounds() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: "contests/rounds/",
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
    apiRoundsLatest() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: "contests/rounds/latest/",
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
    apiRound(roundId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/rounds/${roundId}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
    apiRoundStatus(roundId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/rounds/${roundId}/status/`,
        cache: { maxAge: constants.maxAgeDefaults.short },
      });
    },
    apiRoundEvaluators(roundId, problemId = null, user = null) {
      const params = [
        roundId ? `problem__round=${roundId}` : "",
        problemId ? `problem=${problemId}` : "",
        user ? `user=${user}` : "",
      ]
        .filter((x) => x)
        .join("&");

      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/problems/evaluators/?${params}`,
      });
    },
  },
};
