<template>
  <b-form-checkbox
    v-model="context.model"
    :class="{
      'formulate-input-element': true,
      [`formulate-input-element--${context.type}`]: true,
    }"
    :state="validationState"
    @input="context.blurHandler"
  >
    <!-- eslint-disable vue/no-v-html -->
    <span v-if="htmlLabel" v-html="context.label" />
    <!-- eslint-enable vue/no-v-html -->
    <span v-else>{{ context.label }}</span>
    <span class="text-danger">{{ requiredStar ? " *" : "" }}</span>
  </b-form-checkbox>
</template>

<script>
export default {
  name: "FormCheckbox",
  components: {},
  mixins: [],
  props: {
    context: {
      type: Object,
      required: true,
    },
    htmlLabel: {
      type: Boolean,
      default: false,
    },
    requiredStar: {
      type: Boolean,
      default: false,
    },
    noValidate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    validationState() {
      // Commented out because we wanted to disable green highlighting of valid inputs.
      // if (this.noValidate) return null
      // if (this.context.isValid) return true
      // if (!this.context.isValid && this.context.showValidationErrors) return false
      return null;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
