import { render, staticRenderFns } from "./HomeBanner.vue?vue&type=template&id=6398a49f&scoped=true&"
import script from "./HomeBanner.vue?vue&type=script&lang=js&"
export * from "./HomeBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6398a49f",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BRow, BCol} from 'bootstrap-vue'
    installComponents(component, {BRow, BCol})
    

export default component.exports