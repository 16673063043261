<template>
  <b-row no-gutters>
    <b-col>
      <b-container class="mt-5 mb-3">
        <PasswordSetForm :uid="uid" :token="token" />
      </b-container>
    </b-col>
  </b-row>
</template>

<script>
import apiUsers from "../api/users";
import PasswordSetForm from "../components/forms/PasswordSetForm.vue";

export default {
  name: "PasswordResetConfirm",
  components: {
    PasswordSetForm,
  },
  mixins: [apiUsers],
  props: {
    token: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
