<template>
  <b-container class="mt-5 mb-3">
    <LoadingSpinner :is-loaded="pageLoaded">
      <PageDetail v-if="pageSlug !== '' && page" :page="page" />
      <NotFound v-else />
    </LoadingSpinner>
  </b-container>
</template>

<script>
import apiPages from "../api/pages";
import LoadingSpinner from "../components/utils/LoadingSpinner.vue";
import NotFound from "../components/NotFound.vue";
import PageDetail from "../components/PageDetail.vue";

export default {
  name: "Page",
  components: {
    LoadingSpinner,
    NotFound,
    PageDetail,
  },
  mixins: [apiPages],
  props: {
    pageSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page: null,
      pageLoaded: false,
    };
  },
  computed: {},
  watch: {
    $route() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      this.pageLoaded = false;
      this.page = null;
      if (this.pageSlug !== "") {
        this.page = await this.apiPage(this.pageSlug).catch(() => null);
      }
      this.pageLoaded = true;
    },
  },
};
</script>

<style scoped></style>
