import constants from "../constants";

export default {
  methods: {
    apiStudyMaterials() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: "cms/study-materials/",
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
    apiStudyMaterial(pageSlug) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `cms/study-materials/${pageSlug}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
  },
};
