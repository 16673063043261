<template>
  <b-popover
    :target="target"
    triggers="hover"
    :placement="placement"
    @show="loadThumbnail()"
  >
    <template v-if="title" #title>
      {{ title }}
    </template>

    <LoadingSpinner :is-loaded="loaded" :fixed-width="loadingSpinnerWidth">
      <img :src="imageSrc" alt="Náhľad súboru" />

      <slot />
    </LoadingSpinner>
  </b-popover>
</template>

<script>
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  name: "ThumbnailTooltip",
  components: {
    LoadingSpinner,
  },
  props: {
    target: {
      required: true,
      type: [String, Function, HTMLElement],
    },
    url: {
      required: true,
      type: String,
    },
    placement: {
      default: "right",
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    cacheKey: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      imageSrc: "",
      // Our thumbnails are 500px tall, so they are usually 355px wide
      loadingSpinnerWidth: 355,
      loaded: false,
      loading: false,
    };
  },
  watch: {
    url() {
      this.loaded = false;
      this.loading = false;
      this.imageSrc = "";
    },
    cacheKey() {
      this.loaded = false;
      this.loading = false;
      this.imageSrc = "";
    },
  },
  methods: {
    loadThumbnail() {
      if (this.loaded || this.loading) {
        return;
      }

      this.loading = true;
      this.$root.showImage({ url: this.url }).then((url) => {
        this.imageSrc = url;
        this.loaded = true;
      });
    },
  },
};
</script>
