<template>
  <FormulateForm
    v-model="formData"
    name="password-change"
    :schema="formSchema"
    :errors="fieldErrors"
    @change="(e) => (fieldErrors[e.target.name] = [])"
    @submit="changePassword"
  >
    <div class="text-center">
      <b-button
        size="lg"
        type="submit"
        variant="primary"
        :disabled="changingPassword"
        @click.prevent="$formulate.submit('password-change')"
      >
        <LoadingSpinner :is-loaded="!changingPassword">
          <font-awesome-icon :icon="['fas', 'redo']" />
          Zmeniť heslo
        </LoadingSpinner>
      </b-button>
    </div>
  </FormulateForm>
</template>

<script>
import apiUsers from "../../api/users";
import LoadingSpinner from "../utils/LoadingSpinner.vue";
import schema from "./schema";

export default {
  name: "PasswordChangeForm",
  components: {
    LoadingSpinner,
  },
  mixins: [apiUsers],
  props: {},
  data() {
    return {
      formSchema: schema.applyBlurHandlers(this, [
        schema.visualGroup("Zmena hesla", [
          schema.req(
            {
              type: "password",
              name: "old_password",
              label: "Staré heslo",
              "@change": true,
              autocomplete: "on",
            },
            false,
          ),
          schema.req(
            {
              ...schema.password,
              label: "Nové heslo",
              validation: "min:8,length",
              "@change": true,
            },
            false,
          ),
          schema.req(
            { ...schema.passwordConfirm, label: "Nové heslo znova" },
            false,
          ),
        ]),
      ]),
      formData: {},
      fieldErrors: {},
      changingPassword: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePassword() {
      this.changingPassword = true;
      this.apiChangePassword(this.formData.old_password, this.formData.password)
        .then(() => {
          this.$formulate.reset("password-change");
          this.$root.successToast("Zmena hesla úspešná!");
        })
        .catch(({ response }) => {
          this.fieldErrors = {
            ...response.data,
            password: response.data.new_password2 || [],
          };
          this.$root.dangerToast("Nastala chyba pri zmene hesla!");
        })
        .finally(() => (this.changingPassword = false));
    },
  },
};
</script>

<style scoped></style>
