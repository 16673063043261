import constants from "../constants";

export default {
  methods: {
    apiPostSubmit(problemId, solution) {
      const formData = new FormData();
      formData.append("problem", problemId);
      formData.append("solution", solution, "generated-solution.pdf");
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "submits/submits/",
        data: formData,
      });
    },
    apiSubmits(problemId, latest = true) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `submits/submits/?problem=${problemId}&latest=${latest}`,
      });
    },
    apiSubmitMyLast(problemId, userId) {
      return this.apiSubmits(problemId).then((response) => {
        const submits = response.filter(
          (submit) => submit.enrollment.user.id === userId,
        );
        return submits.length ? submits[submits.length - 1] : null;
      });
    },
    apiSubmitRoundLatest(roundId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `submits/submits/?round=${roundId}&latest=1`,
      });
    },
    apiPatchEvaluation(submitId, data) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }
      return this.$root.request({
        method: constants.httpMethods.PATCH,
        url: `submits/submits/${submitId}/evaluate/`,
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      });
    },
    apiDownloadSubmitsArchive(problemId) {
      return this.$root.downloadFile(
        {
          url: `contests/problems/${problemId}/submits_archive/`,
          cache: { maxAge: constants.maxAgeDefaults.short },
        },
        `riesenia-${problemId}.zip`,
        constants.mimeTypes.ZIP,
      );
    },
    apiPostEvaluatedZipFile(problemId, zipFile) {
      const formData = new FormData();
      formData.append("archive", zipFile, "archive");

      return this.$root.request({
        method: constants.httpMethods.POST,
        url: `submits/problems/${problemId}/archive/`,
        data: formData,
      });
    },
  },
};
