<template>
  <div v-if="show" class="p-3 pl-4 text-white bg-secondary corner-alert">
    <b-row class="d-md-none">
      <b-col
        cols="1"
        class="ml-auto mr-2 mb-auto corner-alert-close-button"
        @click="show = false"
      >
        ×
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1" class="mt-auto mb-auto">
        <div class="pulsate" />
      </b-col>
      <b-col cols="10" class="pl-4">
        <slot />
      </b-col>
      <b-col
        cols="1"
        class="d-none d-md-block mb-auto corner-alert-close-button"
        @click="show = false"
      >
        ×
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CornerAlert",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      show: true,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.corner-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  min-width: 300px;
  width: 50%;
  max-width: 500px;
  background-color: var(--secondary);
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
}

.corner-alert-close-button {
  font-size: 1.8em;
  margin-top: -15px;
  padding-left: 5px;
  cursor: pointer;
}

.pulsate {
  display: inline-block;
  border: 3px solid var(--white);
  border-radius: 300px;
  height: 1.5em;
  width: 1.5em;
  animation: pulsate 1.5s infinite ease-out;
}

@keyframes pulsate {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
</style>
