<template>
  <b-toast
    v-if="variant"
    solid
    no-close-button
    no-auto-hide
    no-fade
    visible
    toaster="b-toaster-top-center"
    :toast-class="toastClass"
    body-class="p-2"
  >
    <b-row no-gutters align-v="center">
      <b-col cols="auto" class="mr-2">
        <AnimatedIconDanger v-if="variant === 'danger'" />
        <AnimatedIconInfo v-else-if="variant === 'info'" />
        <AnimatedIconSuccess v-else-if="variant === 'success'" />
        <AnimatedIconWarning v-else-if="variant === 'warning'" />
      </b-col>
      <b-col :class="`text-${variant} text-center font-weight-bold`">
        {{ text }}
      </b-col>
    </b-row>
  </b-toast>
</template>

<script>
import AnimatedIconDanger from "./utils/AnimatedIconDanger.vue";
import AnimatedIconInfo from "./utils/AnimatedIconInfo.vue";
import AnimatedIconSuccess from "./utils/AnimatedIconSuccess.vue";
import AnimatedIconWarning from "./utils/AnimatedIconWarning.vue";

/*
This component is mounted exactly once (in the App component) and all this.$root.toast() calls just
change its variant and text and restart the animation and duration. Thanks to this, at most one
toast is shown at any moment and default api error messages can be easily overriden without the
need to pass an extra argument to every api call.
*/

export default {
  name: "SingleToast",
  components: {
    AnimatedIconDanger,
    AnimatedIconInfo,
    AnimatedIconSuccess,
    AnimatedIconWarning,
  },
  mixins: [],
  props: {},
  data() {
    return {
      variant: null,
      text: "",
      timeout: null,
      toastClass: "",
    };
  },
  created() {
    this.$root.$on("toast", this.fire);
  },
  methods: {
    fire(variant, text, duration = 4000) {
      clearTimeout(this.timeout);
      this.variant = variant;
      this.text = text;
      this.toastClass = "";
      this.$nextTick(() => (this.toastClass = "toast-show"));
      this.timeout = setTimeout(() => (this.variant = null), duration);
    },
  },
};
</script>

<style lang="scss">
$angle: 1deg;

.toast-show {
  animation: 0.5s ease wiggle;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  30% {
    transform: rotate($angle);
  }
  50% {
    transform: rotate(-$angle);
    opacity: 1;
  }
  70% {
    transform: rotate($angle);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
