<template>
  <div v-if="allErrors" class="form-invalid-feedback">
    <div v-for="(error, index) in allErrors" :key="index">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FormErrors",
  components: {},
  mixins: [],
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    allErrors() {
      return this.context.visibleValidationErrors
        .concat(this.context.errors)
        .filter((error) => error !== true);
      // The type=group inputs sometime contain an error with a value "true"
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.form-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ed5314;
}
</style>
