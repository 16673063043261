<!-- This component should be used mainly with Formulate forms when you need to add a Tooltip
(possibly with HTML) to the FormGroup label. -->

<template>
  <b-form-group v-bind="$attrs">
    <template v-slot:label>
      {{ label }}
      <InfoTooltip v-if="tooltip" :tooltip="tooltip" />
    </template>
    <slot />
  </b-form-group>
</template>

<script>
import InfoTooltip from "../../utils/InfoTooltip.vue";

export default {
  name: "CustomFormGroup",
  components: {
    InfoTooltip,
  },
  mixins: [],
  props: {
    label: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
