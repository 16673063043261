<template>
  <div>
    <div class="text-center">
      <h5>
        Termín:
        <strong>{{ utils.dateTimeToString(endTime) }}</strong>
      </h5>
    </div>
    <b-progress
      :value="(now - adjustedStartTime) / (endTime - adjustedStartTime)"
      :max="1"
      :variant="progressColor"
      class="border"
    />
    <div class="text-right">
      {{ utils.dateFromNow(endTime) }}
    </div>
  </div>
</template>

<script>
import utils from "../../utils";

export default {
  name: "TimeProgress",
  components: {},
  mixins: [],
  props: {
    startTime: {
      type: Date,
      required: true,
    },
    endTime: {
      type: Date,
      required: true,
    },
    now: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      utils,
    };
  },
  computed: {
    progressColor() {
      const remainingHours = (this.endTime - this.now) / (60 * 60 * 1000);
      if (remainingHours < 0) return "info";
      if (remainingHours < 24) return "danger";
      if (remainingHours < 7 * 24) return "warning";
      return "success";
    },
    adjustedStartTime() {
      const d = new Date(this.endTime);
      d.setDate(d.getDate() - 30);
      return Math.max(d, this.startTime);
    },
  },
  methods: {},
};
</script>

<style scoped></style>
