import constants from "../constants";

export default {
  methods: {
    apiPages() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: "cms/pages/",
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
    apiPage(pageSlug) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `cms/pages/${pageSlug}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      });
    },
  },
};
