import AnimatedIconDanger from "../components/utils/AnimatedIconDanger.vue";
import AnimatedIconInfo from "../components/utils/AnimatedIconInfo.vue";
import AnimatedIconSuccess from "../components/utils/AnimatedIconSuccess.vue";
import AnimatedIconWarning from "../components/utils/AnimatedIconWarning.vue";

export default {
  methods: {
    _modalBody(variant, title, text) {
      const icon = {
        danger: AnimatedIconDanger,
        info: AnimatedIconInfo,
        success: AnimatedIconSuccess,
        warning: AnimatedIconWarning,
      }[variant];
      const h = this.$createElement;
      return h("div", { class: "text-center" }, [
        h(icon, { props: { size: "lg" } }),
        h("h2", { class: "mt-3 mb-2" }, [title]),
        text,
      ]);
    },
    _modalConfig(variant, size, modalId = "default-modal-id") {
      return {
        centered: true,
        footerClass: "justify-content-center",
        footerBorderVariant: "white",
        buttonSize: "lg",
        size: size,
        cancelVariant: "info",
        okVariant: variant,
        cancelTitle: "Zrušiť",
        okTitle: "OK",
        id: modalId,
      };
    },
    /*
    Every modalOk call returns a promise which resolves into true after hiding the modal
    (by pressing OK button, ESC key or clicking the backdrop).
    */
    _modalOk(variant, title, text, size, modalId, configOverrides = {}) {
      return this.$bvModal.msgBoxOk(this._modalBody(variant, title, text), {
        ...this._modalConfig(variant, size, modalId),
        ...configOverrides,
      });
    },
    dangerModalOk(title, text, size = "md", modalId, configOverrides = {}) {
      return this._modalOk(
        "danger",
        title,
        text,
        size,
        modalId,
        configOverrides,
      );
    },
    infoModalOk(title, text, size = "md", modalId, configOverrides = {}) {
      return this._modalOk("info", title, text, size, modalId, configOverrides);
    },
    successModalOk(title, text, size = "md", modalId, configOverrides = {}) {
      return this._modalOk(
        "success",
        title,
        text,
        size,
        modalId,
        configOverrides,
      );
    },
    warningModalOk(title, text, size = "md", modalId, configOverrides = {}) {
      return this._modalOk(
        "warning",
        title,
        text,
        size,
        modalId,
        configOverrides,
      );
    },
    /*
    Every modalConfirm call returns a promise which resolves into:
    - true (by pressing OK button),
    - false (by pressing Cancel button), or
    - null (by pressing ESC key or clicking the backdrop).
    */
    _modalConfirm(variant, title, text, size, modalId, configOverrides = {}) {
      return this.$bvModal.msgBoxConfirm(
        this._modalBody(variant, title, text),
        { ...this._modalConfig(variant, size, modalId), ...configOverrides },
      );
    },
    dangerModalConfirm(
      title,
      text,
      size = "md",
      modalId,
      configOverrides = {},
    ) {
      return this._modalConfirm(
        "danger",
        title,
        text,
        size,
        modalId,
        configOverrides,
      );
    },
    infoModalConfirm(title, text, size = "md", modalId, configOverrides = {}) {
      return this._modalConfirm(
        "info",
        title,
        text,
        size,
        modalId,
        configOverrides,
      );
    },
    successModalConfirm(
      title,
      text,
      size = "md",
      modalId,
      configOverrides = {},
    ) {
      return this._modalConfirm(
        "success",
        title,
        text,
        size,
        modalId,
        configOverrides,
      );
    },
    warningModalConfirm(
      title,
      text,
      size = "md",
      modalId,
      configOverrides = {},
    ) {
      return this._modalConfirm(
        "warning",
        title,
        text,
        size,
        modalId,
        configOverrides,
      );
    },
  },
};
