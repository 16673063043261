// One place to store form schemas that are repeated throughout the app
import constants from "../../constants";
import countries from "localized-countries/data/sk";
import CustomFormGroup from "./utils/CustomFormGroup.vue";
import get from "lodash.get";
import set from "lodash.set";

const gdprMessages = constants.gdprMessages;
const _now = new Date();

// Individual fields
const email = {
  type: "email",
  name: "email",
  label: "Email",
  validation: "optional|email",
  tooltip: gdprMessages.email,
  blur: "removeSpaceAndTrim",
};
const password = { type: "password", name: "password", label: "Heslo" };
const passwordConfirm = {
  type: "password",
  name: "password_confirm",
  label: "Potvrdenie hesla",
  validation: "confirm",
};

const firstName = {
  type: "text",
  name: "first_name",
  label: "Krstné meno",
  tooltip: gdprMessages.firstName,
  blur: "trim",
};
const lastName = {
  type: "text",
  name: "last_name",
  label: "Priezvisko",
  tooltip: gdprMessages.lastName,
  blur: "trim",
};
const school = {
  type: "school",
  name: "school",
  label: "Škola",
  tooltip: gdprMessages.school,
  help:
    "V prípade, že sa tvoja škola v zozname nenachádza (napríklad je zahraničná), " +
    'prosím vyber poslednú možnosť – "Iná škola".',
};
const category = {
  type: "category",
  name: "category",
  label: "Ročník / Trieda",
  tooltip: gdprMessages.category,
  help:
    "Zvoľ si ročník, ktorý navštevuješ ku 1.9." +
    (_now.getFullYear() + (_now.getMonth() >= 7 ? 0 : -1)) +
    ".",
};
const sex = {
  type: "vselect",
  name: "sex",
  label: "Pohlavie",
  tooltip: gdprMessages.sex,
  currentOptions: [
    { value: "M", label: "Muž" },
    { value: "F", label: "Žena" },
    { value: "U", label: "Iné/neželám si uviesť" },
  ],
};
const phone = {
  type: "text",
  name: "phone",
  label: "Telefónne číslo",
  validation: "optional|matches:/^[+][0-9]{10}[0-9]?[0-9]?[0-9]?$/",
  tooltip: gdprMessages.phone,
  placeholder: "+421123456789",
  blur: "removeSpaceAndTrim",
};

const street = { type: "text", name: "street", label: "Ulica", blur: "trim" };
const streetNumber = {
  type: "text",
  name: "street_number",
  label: "Číslo domu",
  validation: "max:10,length",
  blur: "trim",
};
const city = { type: "text", name: "city", label: "Obec", blur: "trim" };
const postalNumber = {
  type: "text",
  name: "postal_number",
  label: "PSČ",
  validation: "max:10,length",
  blur: "removeSpaceAndTrim",
};
const country = {
  type: "vselect",
  name: "country",
  label: "Krajina",
  currentOptions: Object.values(countries),
  value: { label: "Slovensko", value: "Slovensko" },
};

const transport = {
  type: "vselect",
  name: "transport",
  label: "Doprava (odkiaľ a ako plánuješ cestovať)",
  tooltip: gdprMessages.eventTransport,
  currentOptions: [],
};
const other = { type: "text", name: "other", label: "Iné poznámky" };
const terms = {
  type: "checkbox",
  name: "terms",
  label: gdprMessages.agreement,
  validation: "required",
  htmlLabel: true,
  validationName: "Súhlas",
  requiredStar: true,
};
const seminaryReference = {
  type: "text",
  name: "reference",
  label: "Odkiaľ si sa dozvedel/-a o Pikomate/Pikofyze?",
  tooltip: gdprMessages.reference,
  placeholder: "Od kamaráta, rodiča, učiteľa, na internete...",
};
const maskedIdentity = {
  type: "checkbox",
  name: "masked_identity",
  label:
    "Vo výsledkovej listine si želám zverejniť len prvé písmeno priezviska.",
  noValidate: true,
};

const publicProfile = {
  type: "checkbox",
  name: "public_profile",
  label: "Zobrazovať základné údaje v zozname vedúcich.",
  noValidate: true,
};
const role = {
  type: "text",
  name: "role",
  label: "Dobrovoľnícka rola v P-MATe",
};

const birthNumber = {
  type: "text",
  name: "birth_number",
  label: "Rodné číslo",
  placeholder: "RRMMDDXXXX",
  tooltip: gdprMessages.birthNumber,
  validation: "matches:/^[0-9]{10}$/",
  blur: "removeSlashAndTrim",
};
const health = {
  type: "text",
  name: "health",
  label: "Zdravotné špecifikácie",
  tooltip: gdprMessages.eventHealth,
  placeholder: "alergie, lieky, ...",
};
const discountRequest = {
  type: "text",
  name: "discount_request",
  label: "Stručné odôvodnenie žiadosti",
  class: "p-2",
  requiredStar: true,
  help: "Po vyplnení prihlášky neplať, ozveme sa ti osobne.",
};

const price = {
  type: "radiobuttons",
  name: "price_name",
  label: "Voľba ceny za sústredenie",
  help:
    "Viac informácií o cene nájdeš" + " vo vyššie uvedenom popise sústredenia.",
  tooltip: gdprMessages.eventPrice,
};
const customPrice = {
  type: "text",
  name: "custom_price",
  label: "Vlastná cena",
  class: "p-2",
  requiredStar: true,
  blur: "removeSpaceAndTrim",
};

const eventTerms = {
  type: "checkbox",
  name: "event_terms",
  label:
    "Súhlasím s " +
    '<a target="_blank" href="/akcie-podmienky">podmienkami účasti na akcii</a>.',
  validation: "required",
  htmlLabel: true,
  validationName: "Súhlas s podmienkami účasti",
  requiredStar: true,
};

// Modifiers
const req = (field, tooltip = null) => ({
  ...field,
  // We do this hacky replacement because:
  //   Fields which have some validation on their own (eg. email/phone),
  //   do not accept empty value even if they don't have the required rule.
  //
  //   If we add the optional rule, then empty values are accepted even if
  //   the rules contain required.
  //
  // In order to achieve the expected behaviour in both optional/required cases
  // we need to use only one of these rules.
  validation: !field.validation
    ? "required"
    : field.validation.includes("optional")
      ? field.validation.replace("optional", "required")
      : field.validation + "|required",
  requiredStar: true,
  ...(tooltip === false && { tooltip: null }),
  ...(tooltip && { tooltip: gdprMessages[tooltip] }),
});
const col = (...fields) => ({
  component: "b-col",
  md: true,
  children: fields,
});
const row = (...children) => ({
  component: "b-form-row",
  children: children.map((child) =>
    col(...(Array.isArray(child) ? child : [child])),
  ),
});
const group = (name, children) => ({
  type: "group",
  name,
  repeatable: false,
  children,
});
const visualGroup = (label, children, tooltip = null) => ({
  component: CustomFormGroup,
  class: "p-2",
  tooltip,
  label,
  children,
});

// Field groups
const parentGroup = visualGroup("Údaje rodiča", [
  group("parent", [
    row(req(firstName, false), req(lastName, false)),
    row(req(email, false), req(phone, false)),
  ]),
]);

const addressGroup = visualGroup("Adresa", [
  group("address", [
    row(req(street), req(streetNumber)),
    req(city),
    row(req(postalNumber), req(country)),
  ]),
]);

// BlurHandlers: Trimming, replacing, etc.
// Sometimes we want to apply a transformation to an input value, after the user blurs a field
const handlers = {
  trim: (value) => value.trim(),
  removeSpaceAndTrim: (value) => value.replace(/\s/g, "").trim(),
  removeSlashAndTrim: (value) => value.replace(/\//g, "").trim(),
};

// This function should be applied to the created formSchema. It recursively crawls
// the formSchema, and alters fields which specify something in the `blur` property.
// It adds inline function to the `@blur` event handler, which alters the value in the formData.
// Reference to the calling component and path to the specific part of data is recursively
// passed to allow creating the final `@blur` handler.
// NOTE: This should not be used on non-text fields (selects, checkboxes, ..)
//       and repeatable groups are NOT SUPPORTED.
const applyBlurHandlers = (component, schema, path = "formData") =>
  schema.map((child) => {
    // If we have children, call applyBlurHandlers recursively on them, with a proper path.
    if (child.children && Array.isArray(child.children))
      return {
        ...child,
        children: applyBlurHandlers(
          component,
          child.children,
          path + (child.name ? `.${child.name}[0]` : ""),
        ),
      };
    // If a child has a name and specifies a `blur` property, we add a `@blur` handler to it.
    // The handlers runs a selected function from the handlers map specified above.
    if (child.name && child.blur)
      return {
        ...child,
        "@blur": () =>
          set(
            component,
            `${path}.${child.name}`,
            handlers[child.blur](get(component, `${path}.${child.name}`, "")),
          ),
      };
    // No children or blur modifier - we return the child unmodified.
    return child;
  });

export default {
  email,
  password,
  passwordConfirm,
  firstName,
  lastName,
  school,
  category,
  sex,
  phone,
  street,
  streetNumber,
  city,
  postalNumber,
  country,
  transport,
  other,
  terms,
  seminaryReference,
  maskedIdentity,
  publicProfile,
  role,
  birthNumber,
  health,
  discountRequest,
  price,
  customPrice,
  req,
  col,
  row,
  group,
  visualGroup,
  parentGroup,
  addressGroup,
  applyBlurHandlers,
  eventTerms,
};
