<template>
  <b-container class="p-3">
    <LoadingSpinner :is-loaded="$root.stateLoaded" class="mt-3">
      <b-row align-h="center">
        <b-col md="12" lg="8" xl="6">
          <div>
            <h2 class="text-primary">Profil – {{ user.full_name }}</h2>
            <small v-if="user.masked_identity" class="text-muted">
              (verejne dostupné je len prvé písmeno priezviska)
            </small>
          </div>
          <em v-if="user.organizer">
            Organizátor
            {{ user.organizer.role ? "- " + user.organizer.role : "" }}
          </em>
          <em v-else-if="user.competitor"> Riešiteľ </em>
          <b-table-simple borderless outlined class="mt-3">
            <b-tbody>
              <b-tr>
                <b-th>Email</b-th>
                <b-td>{{ user.email }}</b-td>
              </b-tr>
              <template v-if="user.organizer">
                <b-tr>
                  <b-th>P-MAT email</b-th>
                  <b-td>{{ user.organizer.organization_email }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>Verejný profil</b-th>
                  <b-td
                    ><CheckOrCross :value="user.organizer.public_profile"
                  /></b-td>
                </b-tr>
              </template>
              <template v-if="user.competitor">
                <b-tr>
                  <b-th>Aktuálna kategória</b-th>
                  <b-td
                    ><b-badge>{{ categoryName }}</b-badge></b-td
                  >
                </b-tr>
                <b-tr>
                  <b-th>Škola</b-th>
                  <b-td>{{ user.competitor.school.abbreviation }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>Meno rodiča</b-th>
                  <b-td>{{ parentFullName || "neuvedené" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>Email rodiča</b-th>
                  <b-td>{{ user.competitor.parent_email || "neuvedený" }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>Telefón rodiča</b-th>
                  <b-td>{{ user.competitor.parent_phone || "neuvedený" }}</b-td>
                </b-tr>
              </template>
              <template v-if="user.sensitive">
                <b-tr>
                  <b-th>Adresa</b-th>
                  <b-td>
                    <div v-for="(line, index) in addressLines" :key="index">
                      {{ line }}
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th>Telefón</b-th>
                  <b-td>{{ user.sensitive.phone || "neuvedený" }}</b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
          <p>
            <b-button
              :to="{ name: 'profile-change' }"
              variant="outline-secondary"
            >
              <font-awesome-icon :icon="['fas', 'user-cog']" /> Upraviť profil
            </b-button>
          </p>
          <p class="mt-4">
            <b-button variant="outline-danger" @click="logoutFromAllDevices()">
              <font-awesome-icon :icon="['fas', 'sign-out-alt']" /> Odhlásiť zo
              všetkých zariadení
            </b-button>
          </p>
        </b-col>
      </b-row>
    </LoadingSpinner>
    <hr class="my-3" />
    <PersonalResultHistory v-if="user.competitor" />
  </b-container>
</template>

<script>
import apiUsers from "../api/users";
import CheckOrCross from "../components/utils/CheckOrCross.vue";
import LoadingSpinner from "../components/utils/LoadingSpinner.vue";
import PersonalResultHistory from "../components/PersonalResultHistory.vue";

export default {
  name: "ProfileDetail",
  components: {
    CheckOrCross,
    LoadingSpinner,
    PersonalResultHistory,
  },
  mixins: [apiUsers],
  props: {},
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$root.state.user;
    },
    addressLines() {
      const address = this.user.sensitive.address;
      return address
        ? [
            `${address.street} ${address.street_number}`,
            `${address.postal_number} ${address.city}`,
            `${address.country}`,
          ]
        : [];
    },
    categoryName() {
      if (this.user.competitor.category) {
        return this.user.competitor.category.name;
      }
      return "";
    },
    parentFullName() {
      const first_name = this.user.competitor.parent_first_name;
      const last_name = this.user.competitor.parent_last_name;
      if (first_name || last_name)
        return `${first_name || "-"} ${last_name || "-"}`;
      return "";
    },
  },
  mounted() {},
  methods: {
    logoutFromAllDevices() {
      this.apiLogoutEverywhere().then(() => {
        this.$root.unsetAuthToken();
        this.$root.successToast("Odhlásenie zo všetkých zariadení úspešné.");
      });
    },
  },
};
</script>
