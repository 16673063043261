var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',[_c('tr',[_c('td',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:('úloha'),expression:"'úloha'",modifiers:{"hover":true,"top":true,"window":true}}]},[_vm._v("ú")]),_vm._l((_vm.round.problems),function(problem){return _c('td',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:(((problem.number) + ". " + (problem.name))),expression:"`${problem.number}. ${problem.name}`",modifiers:{"hover":true,"top":true,"window":true}}],key:problem.id},[_c('b-link',{class:{
            'text-white': true,
            'font-weight-bold': _vm.$root.isProblemRecommendedForMe(problem),
          },attrs:{"to":{
            name: 'round',
            params: { roundId: _vm.round.id },
            hash: ("#uloha-" + (problem.number)),
          }}},[_vm._v(" "+_vm._s(problem.number)),(_vm.$root.isProblemRecommendedForMe(problem))?_c('sup',[_c('small',[_c('FavoriteStar')],1)]):_vm._e()])],1)})],2),_c('tr',[_c('td',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:('body'),expression:"'body'",modifiers:{"hover":true,"top":true,"window":true}}]},[_vm._v("b")]),_vm._l((_vm.round.problems),function(problem){return _c('td',{key:problem.id},[(_vm.resultsRow.points)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:(_vm.getProblemPopover(problem.id)),expression:"getProblemPopover(problem.id)",modifiers:{"hover":true,"top":true,"window":true}}],class:_vm.resultsRow.points_counted[problem.id]
              ? 'font-weight-bold'
              : 'text-muted'},[_vm._v(" "+_vm._s(problem.id in _vm.resultsRow.points ? _vm.resultsRow.points[problem.id] : problem.submit_solution || problem.evaluation_feedback ? "-" : "")+" ")]):_vm._e(),(problem.evaluation_feedback && !problem.interactive)?_c('div',[_c('span',{class:{ 'icon-indicator': !problem.evaluation_seen }},[_c('font-awesome-icon',{ref:("icon-" + (problem.id)),refInFor:true,staticClass:"clickable",attrs:{"icon":['fas', 'comment']},on:{"click":function($event){return _vm.downloadEvaluation(problem)}}})],1),(problem.evaluation_thumbnail)?_c('ThumbnailTooltip',{attrs:{"placement":"bottom","target":function () { return _vm.$refs[("icon-" + (problem.id))][0]; },"url":problem.evaluation_thumbnail,"title":"Ohodnotené riešenie"}}):_vm._e()],1):(problem.submit_solution && !problem.interactive)?_c('div',{ref:("icon-" + (problem.id)),refInFor:true},[_c('font-awesome-icon',{staticClass:"clickable",attrs:{"icon":['far', 'envelope']},on:{"click":function($event){return _vm.downloadSubmit(problem)}}}),(problem.submit_thumbnail)?_c('ThumbnailTooltip',{attrs:{"placement":"bottom","target":function () { return _vm.$refs[("icon-" + (problem.id))][0]; },"url":problem.submit_thumbnail,"title":"Odovzdané riešenie"}}):_vm._e()],1):(!!problem.interactive)?_c('div',[_c('font-awesome-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:('Interaktívna úloha'),expression:"'Interaktívna úloha'",modifiers:{"hover":true,"top":true,"window":true}}],attrs:{"icon":['fas', 'gamepad']}})],1):_vm._e()])})],2)]),_c('ResultsRowSum',{attrs:{"row":_vm.resultsRow}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }