<template>
  <b-button
    v-b-popover.hover.top.window="danger && dangerTooltip"
    v-bind="attributes"
    @click="$emit('click')"
  >
    <font-awesome-icon
      v-if="danger && dangerIcon"
      :icon="dangerIcon"
      class="mr-1"
    />
    <slot />
  </b-button>
</template>

<script>
export default {
  name: "DangerButton",
  components: {},
  mixins: [],
  props: {
    danger: {
      type: Boolean,
      default: false,
    },
    dangerIcon: {
      type: Array,
      default: () => ["fas", "exclamation"],
    },
    dangerTooltip: {
      type: String,
      default: null,
    },
    dangerVariant: {
      type: String,
      default: "danger",
    },
  },
  data() {
    return {};
  },
  computed: {
    attributes() {
      const attributes = this.$attrs;
      if (this.danger) attributes.variant = this.dangerVariant;
      return attributes;
    },
  },
  methods: {},
};
</script>
