<template>
  <b-row>
    <b-col class="text-center text-danger">
      <h1>Stránku sa nepodarilo nájsť.</h1>
      <b-img src="/logos/pmat-no-margin.svg" fluid class="mt-5" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
