import { render, staticRenderFns } from "./PostList.vue?vue&type=template&id=a9542d16&scoped=true&"
import script from "./PostList.vue?vue&type=script&lang=js&"
export * from "./PostList.vue?vue&type=script&lang=js&"
import style0 from "./PostList.vue?vue&type=style&index=0&id=a9542d16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9542d16",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCard} from 'bootstrap-vue'
    installComponents(component, {BButton, BCard})
    

export default component.exports