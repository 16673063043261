import { render, staticRenderFns } from "./ProfileChangeForm.vue?vue&type=template&id=48a8d1ba&scoped=true&"
import script from "./ProfileChangeForm.vue?vue&type=script&lang=js&"
export * from "./ProfileChangeForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a8d1ba",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BButton} from 'bootstrap-vue'
    installComponents(component, {BAlert, BButton})
    

export default component.exports