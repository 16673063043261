<template>
  <div>
    <VueSelect
      v-model="context.model"
      :data-type="context.type"
      :class="{
        'formulate-input-element': true,
        [`formulate-input-element--${context.type}`]: true,
        valid: context.isValid,
        invalid: !context.isValid && context.showValidationErrors,
      }"
      :options="dictOptions"
      :filterable="!search"
      :selectable="(option) => !option.disabled"
      @search="onSearch"
      @search:blur="context.blurHandler"
      @input="
        otherOpened = arguments[0] && arguments[0].label === otherOptionLabel
      "
    >
      <template #no-options="{ search: query }">
        <template v-if="!query"> Začnite písať pre vyhľadávanie... </template>
        <template v-else> Žiadne možnosti nevyhovujú vyhľadávaniu. </template>
      </template>

      <template #option="{ label: optionLabel }">
        <small
          :class="{
            'wrapped-option': true,
            'special-option':
              specialPrefix && optionLabel.startsWith(specialPrefix),
          }"
        >
          {{ optionLabel }}
        </small>
      </template>

      <template #selected-option="{ label: optionLabel }">
        <span v-if="!otherOpened">
          {{ optionLabel }}
        </span>
        <span v-else>
          {{ otherOptionLabel }}
        </span>
      </template>
    </VueSelect>
    <FormulateInput
      v-if="otherOpened"
      key="otherText"
      v-model="context.model.other"
      ignored
      class="p-2"
      help-position="before"
      help="Uveď inú odpoveď:"
      type="text"
    />
  </div>
</template>

<script>
import "vue-select/src/scss/vue-select.scss";
import debounce from "lodash.debounce";
import VueSelect from "vue-select";

export default {
  name: "FormSelect",
  components: {
    VueSelect,
  },
  mixins: [],
  props: {
    context: {
      type: Object,
      required: true,
    },
    currentOptions: {
      type: Array,
      required: true,
    },
    search: {
      type: Function,
      default: null,
    },
    specialPrefix: {
      type: String,
      default: null,
    },
    otherTextAllowed: {
      type: Boolean,
      default: false,
    },
    otherOptionLabel: {
      type: String,
      default: "Iné",
    },
  },
  data() {
    return {
      otherOpened: false,
    };
  },
  computed: {
    dictOptions() {
      let options;
      if (this.currentOptions === null || this.currentOptions.length === 0) {
        options = [];
      } else if (this.currentOptions[0] instanceof Object) {
        options = this.currentOptions;
      } else {
        options = this.currentOptions.map((option) => ({
          value: option,
          label: option,
        }));
      }

      return [
        ...options,
        ...(this.otherTextAllowed
          ? [{ value: "other", label: this.otherOptionLabel }]
          : []),
      ];
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onSearch(query, loading) {
      // This disables search with an empty query - not ideal, but not deal breaking.
      // It is required because otherwise when de-focusing the select, the onSearch
      // is triggered with empty query and that causes a failure
      // during label lookup in currentOption.
      if (!this.search || !query) return;
      loading(true);
      this.debouncedSearch(query, loading, this.search);
    },
    debouncedSearch: debounce((query, loading, search) => {
      search(query).finally(() => loading(false));
    }, 300),
  },
};
</script>

<style lang="scss">
@import "frontend-common/themes/common-variables.scss";

.formulate-input[data-classification="select"] .formulate-input-element {
  .vs__dropdown-toggle {
    border-radius: 0;
    /* This matches the height to the bootstrap components */
    height: $input-height;
  }

  .vs__dropdown-option--highlight {
    background-color: var(--primary);
  }

  .vs__dropdown-option .special-option {
    font-weight: bold;
    text-decoration: underline;
  }

  .vs__selected {
    overflow: hidden;
    white-space: nowrap;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .vs__selected-options {
    width: 0;
  }

  // Commented out because we wanted to disable green highlighting of valid inputs.
  // &.valid {
  //   .vs__dropdown-toggle {
  //     border: 1px solid var(--success);
  //   }

  //   .vs__open-indicator {
  //     fill: var(--success);
  //   }
  // }

  &.invalid {
    .vs__dropdown-toggle {
      border: 1px solid var(--danger);
    }

    .vs__open-indicator {
      fill: var(--danger);
    }
  }

  // The next two rules ensure "too long" options are nicely wrapped.
  .wrapped-option {
    white-space: normal;
  }

  &.vs--open .vs__selected {
    left: 4px;
    right: 0;
  }
}
</style>
