import deburr from "lodash.deburr";
import moment from "moment";

export default {
  dateTimeToString(date) {
    return moment(date).format("D. M. YYYY, HH:mm");
  },
  dateToString(date) {
    return moment(date).format("D. M. YYYY");
  },
  datesToDuration(startDate, endDate) {
    return (
      moment(startDate).format("D. M.") +
      " – " +
      moment(endDate).format("D. M. YYYY")
    );
  },
  dateFromNow(date) {
    return moment(date).fromNow();
  },
  daysAgo(days) {
    return new Date(Date.now() - days * 24 * 60 * 60 * 1000);
  },
  problemPopover(points, counted) {
    if (points === "*") {
      return "Toto riešenie ešte nie je ohodnotené.";
    }
    return counted
      ? ""
      : "Body za túto úlohu sa ti nezarátavajú do súčtu (pozri pravidlá súťaže).";
  },
  fullRoundName(contestName, roundName) {
    return `${contestName}, ${roundName}`;
  },
  fullProblemName(fullRoundName, problemNumber, problemName) {
    return `${fullRoundName} - ${problemNumber}. ${problemName}`;
  },
  sanitizeFilename(rawName) {
    return deburr(rawName)
      .replace(/[^a-z0-9]/gi, "-")
      .replace(/-+/gi, "-")
      .toLowerCase();
  },
};
