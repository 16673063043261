import {
  AlertPlugin,
  BadgePlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  ImagePlugin,
  JumbotronPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  OverlayPlugin,
  ProgressPlugin,
  SidebarPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  VBPopoverPlugin,
  VBScrollspyPlugin,
  VBTogglePlugin,
} from "bootstrap-vue";

export default [
  AlertPlugin,
  BadgePlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  ImagePlugin,
  JumbotronPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  OverlayPlugin,
  ProgressPlugin,
  SidebarPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  VBPopoverPlugin,
  VBScrollspyPlugin,
  VBTogglePlugin,
];
