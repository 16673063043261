<template>
  <AnimatedIcon :size="size">
    <circle cx="0" cy="0" r="50" class="animated-circle warning-circle" />
    <path d="M 0 -28 L 0 10" class="animated-path warning-line" />
    <circle cx="0" cy="28" r="5" class="animated-dot warning-dot" />
  </AnimatedIcon>
</template>

<script>
import AnimatedIcon from "./AnimatedIcon.vue";

export default {
  name: "AnimatedIconWarning",
  components: {
    AnimatedIcon,
  },
  mixins: [],
  props: {
    size: {
      type: String,
      default: "sm",
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.warning-circle {
  stroke: var(--warning);
  transform: scaleX(-1) rotate(-90deg);
}

.warning-line {
  stroke: var(--warning);
  stroke-dasharray: 40;
  stroke-dashoffset: 40;
}

.warning-dot {
  stroke: var(--warning);
  fill: var(--warning);
}
</style>
