import { setupCache } from "axios-cache-adapter";
import analytics from "./analytics";
import axios from "axios";
import constants from "../constants";
import download from "downloadjs";
import localforage from "localforage";

// Api cache setup. MaxAge is by default 0, so caching is opt-in for every api endpoint.
// Set 'ignoreCache: false' to enable caching; 'debug: true' for console logging.
const cache = setupCache({
  maxAge: 0,
  exclude: { query: false },
  store: localforage.createInstance({ name: "api-cache" }),
  ignoreCache: constants.axiosIgnoreCache,
  debug: false,
});

export default {
  mixins: [analytics],
  data() {
    return {
      axiosInstance: axios.create({
        // baseURL is prepended to config.url if config.url is absolute, else baseURL is ignored
        baseURL: `${constants.backendUrl}/${constants.backendApiNamespace}`,
        transformRequest: [
          (data, headers) => {
            // TODO verify that we send our token only to the backend url and not to an external site
            if (this.$root.isAuthenticated) {
              headers["Authorization"] = `Token ${this.$root.storage.token}`;
            }
            if (constants.apiIgnoreSites) {
              headers[constants.apiIgnoreSitesHeader] = "1";
            }

            const hasFile =
              data && Object.values(data).some((val) => val instanceof File);
            if (hasFile) {
              const formData = new FormData();
              for (const [key, value] of Object.entries(data)) {
                formData.set(key, value);
              }
              data = formData;
            }
            return data;
          },
          ...axios.defaults.transformRequest,
        ],
        adapter: cache.adapter,
      }),
      backendAdminBaseURL: `${constants.backendUrl}/admin/`,
    };
  },
  methods: {
    request(config, rawResponse = false) {
      let response = this.axiosInstance(config);

      if (!rawResponse) response = response.then((response) => response.data);

      return response.catch((error) => {
        if (error.request.status === 401) {
          this.$root.unsetAuthToken();
          this.$root.warningToast("Bol si odhlásený.");
        } else {
          this.$root.dangerToast("Pri načítavaní údajov došlo k chybe.");
        }
        throw error;
      });
    },
    downloadFile(config, fileName, fileType) {
      return this.request({ ...config, responseType: "blob" }).then(
        (response) => download(response, fileName, fileType),
      );
    },
    showImage(config) {
      return this.request({ ...config, responseType: "blob" }).then(
        (response) => Promise.resolve(URL.createObjectURL(response)),
      );
    },
    downloadSubmit(url, roundNumber, problemNumber) {
      this.downloadFile(
        { url, cache: { maxAge: constants.maxAgeDefaults.long } },
        `riesenie-${roundNumber}-seria-${problemNumber}-uloha.pdf`,
        constants.mimeTypes.PDF,
      );
    },
    downloadEvaluation(url, roundNumber, problemNumber, analyticsProblemName) {
      this.downloadFile(
        { url, cache: { maxAge: constants.maxAgeDefaults.long } },
        `hodnotenie-${roundNumber}-seria-${problemNumber}-uloha.pdf`,
        constants.mimeTypes.PDF,
      );
      this.trackDownloadEvaluation(analyticsProblemName);
    },
  },
};
