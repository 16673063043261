<template>
  <div v-if="row && row.sum && row.position" class="mt-3">
    Celkovo:
    <span class="text-nowrap"
      ><strong>{{ row.sum }}</strong> bodov,
    </span>
    <span class="text-nowrap"
      ><strong>{{ row.position }}.</strong> miesto
    </span>
    <span
      v-if="row.change !== null"
      v-b-popover.hover.top.window="
        row.change === 0
          ? 'Toto umiestnenie je rovnaké ako v predchádzajúcej sérii.'
          : ''
      "
      class="text-nowrap"
    >
      (<PositionChange :change="row.change" :color="false" />)
    </span>
    <p v-if="previous && row.previous_points">
      ({{ row.previous_points }} z predošlých sérií)
    </p>
  </div>
</template>

<script>
import PositionChange from "./PositionChange.vue";

export default {
  name: "ResultsRowSum",
  components: {
    PositionChange,
  },
  mixins: [],
  props: {
    row: {
      type: Object,
      required: true,
    },
    previous: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
