<template>
  <div>
    <table>
      <tr>
        <td v-b-popover.hover.top.window="'úloha'">ú</td>
        <td
          v-for="problem of round.problems"
          :key="problem.id"
          v-b-popover.hover.top.window="`${problem.number}. ${problem.name}`"
        >
          <b-link
            :class="{
              'text-white': true,
              'font-weight-bold': $root.isProblemRecommendedForMe(problem),
            }"
            :to="{
              name: 'round',
              params: { roundId: round.id },
              hash: `\#uloha-${problem.number}`,
            }"
          >
            {{ problem.number
            }}<sup v-if="$root.isProblemRecommendedForMe(problem)">
              <small><FavoriteStar /></small>
            </sup>
          </b-link>
        </td>
      </tr>

      <tr>
        <td v-b-popover.hover.top.window="'body'">b</td>
        <td v-for="problem of round.problems" :key="problem.id">
          <div
            v-if="resultsRow.points"
            v-b-popover.hover.top.window="getProblemPopover(problem.id)"
            :class="
              resultsRow.points_counted[problem.id]
                ? 'font-weight-bold'
                : 'text-muted'
            "
          >
            {{
              problem.id in resultsRow.points
                ? resultsRow.points[problem.id]
                : problem.submit_solution || problem.evaluation_feedback
                  ? "-"
                  : ""
            }}
          </div>

          <div v-if="problem.evaluation_feedback && !problem.interactive">
            <span :class="{ 'icon-indicator': !problem.evaluation_seen }">
              <font-awesome-icon
                :ref="`icon-${problem.id}`"
                :icon="['fas', 'comment']"
                class="clickable"
                @click="downloadEvaluation(problem)"
              />
            </span>

            <ThumbnailTooltip
              v-if="problem.evaluation_thumbnail"
              placement="bottom"
              :target="() => $refs[`icon-${problem.id}`][0]"
              :url="problem.evaluation_thumbnail"
              title="Ohodnotené riešenie"
            />
          </div>
          <div
            v-else-if="problem.submit_solution && !problem.interactive"
            :ref="`icon-${problem.id}`"
          >
            <font-awesome-icon
              :icon="['far', 'envelope']"
              class="clickable"
              @click="downloadSubmit(problem)"
            />

            <ThumbnailTooltip
              v-if="problem.submit_thumbnail"
              placement="bottom"
              :target="() => $refs[`icon-${problem.id}`][0]"
              :url="problem.submit_thumbnail"
              title="Odovzdané riešenie"
            />
          </div>
          <div v-else-if="!!problem.interactive">
            <font-awesome-icon
              v-b-popover.hover.top.window="'Interaktívna úloha'"
              :icon="['fas', 'gamepad']"
            />
          </div>
        </td>
      </tr>
    </table>
    <ResultsRowSum :row="resultsRow" />
  </div>
</template>

<script>
import { ResultsRowSum, ThumbnailTooltip, utils } from "frontend-common";
import FavoriteStar from "./utils/FavoriteStar.vue";

export default {
  name: "PersonalResultTable",
  components: {
    FavoriteStar,
    ResultsRowSum,
    ThumbnailTooltip,
  },
  mixins: [],
  props: {
    round: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    resultsRow() {
      return this.round.results?.row || {};
    },
  },
  mounted() {},
  methods: {
    getProblemPopover(problemId) {
      return utils.problemPopover(
        this.resultsRow.points[problemId],
        this.resultsRow.points_counted[problemId],
      );
    },
    downloadSubmit(problem) {
      this.$root.downloadSubmit(
        problem.submit_solution,
        this.round.number,
        problem.number,
      );
    },
    downloadEvaluation(problem) {
      this.$root.downloadEvaluation(
        problem.evaluation_feedback,
        this.round.number,
        problem.number,
        utils.fullProblemName(
          utils.fullRoundName(this.round.contest.name, this.round.name),
          problem.number,
          problem.name,
        ),
      );

      problem.evaluation_seen = true;
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  table-layout: fixed;
}

table td {
  vertical-align: text-top;
  border: 1px solid var(--secondary);
}

.clickable {
  cursor: pointer;
}
</style>
