<template>
  <LoadingSpinner :is-loaded="postsLoaded">
    <transition-group name="posts-list">
      <Post
        v-for="post of posts"
        :key="post.slug"
        class="mb-3 posts-list-item"
        :post="post"
      />
    </transition-group>
    <div class="m-5">
      <b-button
        v-if="nextPage"
        variant="primary"
        class="p-3"
        block
        :disabled="loadingMore"
        @click="loadNextPage"
      >
        <LoadingSpinner :is-loaded="!loadingMore" small>
          Načítať staršie novinky
        </LoadingSpinner>
      </b-button>
      <b-card v-else-if="!nextPage && posts.length > 3" class="p-3 text-center">
        Staršie novinky už neexistujú.
      </b-card>
    </div>
  </LoadingSpinner>
</template>

<script>
import { apiPosts, LoadingSpinner } from "frontend-common";
import Post from "@/components/Post.vue";

export default {
  name: "PostList",
  components: {
    LoadingSpinner,
    Post,
  },
  mixins: [apiPosts],
  props: {},
  data() {
    return {
      posts: [],
      postsLoaded: false,
      loadingMore: false,
      nextPage: 1,
    };
  },
  computed: {},
  mounted() {
    this.loadNextPage().finally(() => (this.postsLoaded = true));
  },
  methods: {
    loadNextPage(e) {
      // The blur prevents the page from scrolling to the button after adding the new posts.
      if (e) e.target.blur();
      this.loadingMore = true;

      return this.apiPosts(this.nextPage)
        .then((response) => {
          this.nextPage = response.next ? this.nextPage + 1 : null;
          this.posts = this.posts.concat(response.results);
        })
        .finally(() => (this.loadingMore = false));
    },
  },
};
</script>

<style scoped>
.posts-list-item {
  transition: all 1s;
}
.posts-list-enter {
  opacity: 0;
  transform: translateX(200px);
}
</style>
