<template>
  <font-awesome-icon
    :icon="['fas', warning ? 'exclamation' : value ? 'check' : 'times']"
    :class="warning ? 'text-warning' : value ? 'text-success' : 'text-danger'"
  />
</template>

<script>
export default {
  name: "CheckOrCross",
  components: {},
  mixins: [],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
