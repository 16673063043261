<template>
  <b-nav vertical pills>
    <LoadingSpinner :is-loaded="$root.stateLoaded">
      <b-nav-item
        link-classes="text-secondary"
        class="menu-entry mb-1 border-bottom"
        @click="$emit('toggle-collapsed')"
      >
        <font-awesome-icon
          :icon="[
            'fas',
            collapsed ? 'angle-double-right' : 'angle-double-left',
          ]"
          fixed-width
        />
      </b-nav-item>
      <SideMenuEntry
        v-for="entry of $root.state.menu"
        :key="entry.label"
        :entry="entry"
        :collapsed="collapsed"
      />
      <template v-if="$root.seminary">
        <SideMenuEntry
          v-for="seminary in $root.seminary.otherSeminary"
          :key="seminary.title"
          :entry="{
            label: seminary.title,
            entry_type: 'section',
            url: seminary.url,
          }"
          :collapsed="collapsed"
          class="d-lg-none"
        />
        <SideMenuEntry
          :key="'P-MAT'"
          :entry="{
            label: 'P-MAT',
            entry_type: 'section',
            url: 'https://p-mat.sk',
          }"
          :collapsed="collapsed"
          class="d-lg-none"
        />
      </template>
      <template v-else>
        <SideMenuEntry
          v-for="seminary in constants.allSeminary"
          :key="seminary.title"
          :entry="{
            label: seminary.title,
            entry_type: 'section',
            url: seminary.url,
          }"
          :collapsed="collapsed"
          class="d-lg-none"
        />
      </template>
    </LoadingSpinner>
  </b-nav>
</template>

<script>
import constants from "../constants";
import LoadingSpinner from "./utils/LoadingSpinner.vue";
import SideMenuEntry from "./SideMenuEntry.vue";

export default {
  name: "SideMenu",
  components: {
    LoadingSpinner,
    SideMenuEntry,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      constants,
    };
  },
};
</script>

<style scoped></style>
