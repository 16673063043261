import constants from "../constants";

export default {
  methods: {
    apiUserMe() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: "users/users/me/",
      });
    },
    apiPatchUser(userId, data) {
      return this.$root.request({
        method: constants.httpMethods.PATCH,
        url: `/users/users/${userId}/`,
        data,
      });
    },
    apiRegisterEmail(user_data) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/registration/",
        data: { user_data },
      });
    },
    apiLoginEmail(email, password) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/login/",
        data: { email, password },
      });
    },
    apiRegisterGoogle(auth_code, user_data) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/google/registration/",
        data: { auth_code, user_data },
      });
    },
    apiLoginGoogle(auth_code) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/google/login/",
        data: { auth_code },
      });
    },
    apiChangePassword(oldPassword, newPassword) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/password/change/",
        data: {
          old_password: oldPassword,
          new_password1: newPassword,
          new_password2: newPassword,
        },
      });
    },
    apiRegisterOrganizer(auth_code, user_data) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/google/registration/organizer/",
        data: { auth_code, user_data },
      });
    },
    /**
     * Logs out from all devices by invalidating the token on backend. Use with caution!
     */
    apiLogoutEverywhere() {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/logout/",
      });
    },
    apiSchools(query) {
      return this.$root
        .request({
          method: constants.httpMethods.GET,
          url: `users/schools/?page_size=10&search=${query}`,
        })
        .then((response) => ({
          results: response.results,
          tooManyResults: response.count > 10,
        }));
    },
    apiResetPassword(email, recaptcha) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/password/reset/",
        data: {
          email,
          recaptcha,
        },
      });
    },
    apiResetPasswordConfirm(uid, token, newPassword) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/password/reset/confirm/",
        data: {
          uid,
          token,
          new_password1: newPassword,
          new_password2: newPassword,
        },
      });
    },
    apiEmailVerifyToken(token) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: `users/email/verify/${token}/`,
      });
    },
    apiEmailSendVerification(email, recaptcha) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: "users/email/resend/",
        data: {
          email,
          recaptcha,
        },
      });
    },
  },
};
