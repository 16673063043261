<template>
  <b-col sm="5" class="my-3 bg-primary">
    <b-button
      variant="primary"
      class="text-white border px-0 py-4"
      :to="to"
      block
    >
      <font-awesome-icon :icon="icon" size="3x" />
      <h5 class="m-0 mt-1">
        {{ title }}
      </h5>
    </b-button>
  </b-col>
</template>

<script>
export default {
  name: "HomeBannerButton",
  components: {},
  mixins: [],
  props: {
    icon: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
