import { render, staticRenderFns } from "./MainNavbar.vue?vue&type=template&id=289014f4&scoped=true&"
import script from "./MainNavbar.vue?vue&type=script&lang=js&"
export * from "./MainNavbar.vue?vue&type=script&lang=js&"
import style0 from "./MainNavbar.vue?vue&type=style&index=0&id=289014f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289014f4",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BSidebar, BImg, BNavbarBrand, BNavbarNav, BNavbar} from 'bootstrap-vue'
    installComponents(component, {BButton, BSidebar, BImg, BNavbarBrand, BNavbarNav, BNavbar})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal})
    

export default component.exports