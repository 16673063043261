<template>
  <span>
    <FormSelect :context="context" :current-options="categories" />
    <span v-if="pikomatTooYoungCategory" class="form-text small text-primary">
      <font-awesome-icon
        :icon="['fas', 'exclamation']"
        class="mr-1 text-primary"
      />
      Pikomat je určený najmä pre piatakov a starších.
      <b-link v-b-modal.pikomat-too-young class="text-nowrap"
        ><u>Čo to znamená?</u></b-link
      >
      <b-modal
        id="pikomat-too-young"
        ok-only
        centered
        :title="`Môžem sa zapojiť aj keď patrím do mladšej kategórie?`"
      >
        <p>
          Pikomat je obtiažnosťou nastavený pre žiakov 5. ročníka a starších,
          riešenie preto pre teba môže byť náročnejšie.
        </p>
        <p>
          Budeme radi, ak sa zapojíš aj ako mladší riešiteľ/-ka, avšak
          <strong>vo výsledkoch ťa zaradíme do kategórie 5</strong>. Zároveň ti
          v profile budeme zobrazovať, že chodíš do 5. ročníku, kým budeš
          mladší/-ia.
        </p>
      </b-modal>
    </span>
    <span v-if="pikofyzTooYoungCategory" class="form-text small text-primary">
      <font-awesome-icon
        :icon="['fas', 'exclamation']"
        class="mr-1 text-primary"
      />
      Pikofyz je určený najmä pre siedmakov a starších.
      <b-link v-b-modal.pikofyz-too-young class="text-nowrap"
        ><u>Čo to znamená?</u></b-link
      >
      <b-modal
        id="pikofyz-too-young"
        ok-only
        centered
        :title="`Môžem sa zapojiť aj keď patrím do mladšej kategórie?`"
      >
        <p>
          Pikofyz je obtiažnosťou nastavený pre žiakov 7. ročníka (resp.
          Sekundy) a starších, riešenie preto pre teba môže byť náročnejšie.
        </p>
        <p v-if="youngestCategory">
          Budeme radi, ak sa zapojíš aj ako mladší riešiteľ/-ka, avšak
          <strong>vo výsledkoch ťa zaradíme do kategórie 7</strong>. Zároveň ti
          v profile budeme zobrazovať, že chodíš do 5. ročníku, kým budeš
          mladší/-ia.
        </p>
        <p v-else>
          Budeme radi, ak sa zapojíš aj ako mladší riešiteľ/-ka z kategórií 5, 6
          alebo Príma, avšak
          <strong
            >vo výsledkoch ťa zaradíme do kategórie 7 (resp. Sekunda)</strong
          >.
        </p>
      </b-modal>
    </span>
  </span>
</template>

<script>
import apiContests from "../../../api/contests";
import constants from "../../../constants";
import FormSelect from "./FormSelect.vue";

const youngestCategoryLabel = "4 a menej";

export default {
  name: "FormSelectCategory",
  components: {
    FormSelect,
  },
  mixins: [apiContests],
  props: {
    context: {
      type: Object,
      required: true,
    },
    showYoungWarnings: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      categories: [
        { label: "Načítavame možnosti...", value: null, disabled: true },
      ],
    };
  },
  computed: {
    // These are hardcoded, but let's deal with it.
    youngestCategory() {
      return youngestCategoryLabel === this.context.model.label;
    },
    pikomatTooYoungCategory() {
      return (
        this.showYoungWarnings &&
        this.$root.seminary.title === constants.pikomatSeminary.title &&
        youngestCategoryLabel === this.context.model.label
      );
    },
    pikofyzTooYoungCategory() {
      return (
        this.showYoungWarnings &&
        this.$root.seminary.title === constants.pikofyzSeminary.title &&
        [youngestCategoryLabel, "5", "6", "Príma"].includes(
          this.context.model.label,
        )
      );
    },
  },
  mounted() {
    this.apiCategories()
      .then((response) =>
        response.map((category) => ({
          label: category.name,
          value: category.id,
        })),
      )
      .then(
        (categories) =>
          (this.categories = [
            { label: youngestCategoryLabel, value: categories[0].value },
            ...categories,
          ]),
      );
  },
  methods: {},
};
</script>

<style scoped></style>
