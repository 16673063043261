<template>
  <b-badge
    v-b-popover.hover.top="tooltip"
    :variant="recommended ? 'primary' : 'light'"
    class="ml-1"
  >
    <slot />
  </b-badge>
</template>

<script>
export default {
  name: "CategoryBadge",
  components: {},
  mixins: [],
  props: {
    recommended: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
