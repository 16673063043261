<template>
  <div>
    <b-form-radio-group
      v-model="context.model"
      buttons
      stacked
      button-variant="outline-primary"
      class="w-100 p-2"
      @input="context.blurHandler"
    >
      <b-form-radio
        v-for="option in currentOptions"
        :key="option.value"
        :value="option.value"
        :class="{
          'p-2': true,
          'p-4': context.model === option.value,
          'btn-outline-primary': context.model === option.value,
        }"
      >
        {{ option.label }}
      </b-form-radio>
    </b-form-radio-group>
  </div>
</template>

<script>
export default {
  name: "FormRadioButtons",
  components: {},
  mixins: [],
  props: {
    context: {
      type: Object,
      required: true,
    },
    currentOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.btn-outline-success {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.btn-outline-primary {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
</style>
