<template>
  <AnimatedIcon :size="size">
    <circle cx="0" cy="0" r="50" class="animated-circle danger-circle" />
    <path d="M -21 21 L 21 -21" class="animated-path danger-line" />
    <path d="M 21 21 L -21 -21" class="animated-path danger-line" />
  </AnimatedIcon>
</template>

<script>
import AnimatedIcon from "./AnimatedIcon.vue";

export default {
  name: "AnimatedIconDanger",
  components: {
    AnimatedIcon,
  },
  mixins: [],
  props: {
    size: {
      type: String,
      default: "sm",
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.danger-circle {
  stroke: var(--danger);
  transform: scaleX(-1) rotate(90deg);
}

.danger-line {
  stroke: var(--danger);
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
}
</style>
