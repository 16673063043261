<template>
  <b-row align-v="center">
    <b-col lg="6">
      <div class="d-lg-none">
        <b-img
          src="/logos/pmat-horizontal.svg"
          fluid
          center
          alt="Logo P-MATu"
        />
      </div>
      <div class="d-none d-lg-block">
        <b-img src="/logos/pmat-vertical.svg" fluid center alt="Logo P-MATu" />
      </div>
    </b-col>
    <b-col lg="6">
      <p class="small text-info">
        Ak si mal/-a účet na starých stránkach Pikomatu/Pikofyzu, musíš si
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        <b-link @click="$emit('switch-to-register')">vytvoriť nový účet</b-link
        >.
      </p>
      <FormulateForm
        v-model="formData"
        :schema="formSchema"
        @submit="loginEmail"
      >
        <FormulateInput type="submit" :errors="formErrors">
          <LoadingSpinner :is-loaded="!ongoingLogin" small>
            Prihlásiť sa emailom
          </LoadingSpinner>
        </FormulateInput>
      </FormulateForm>

      <div class="text-center my-2">alebo</div>

      <div class="text-center">
        <GoogleButton
          :is-loaded="!ongoingGoogleLogin"
          :on-click="loginGoogle"
          label="Prihlásiť sa pomocou Google"
        />
      </div>

      <hr />
      <b-button v-b-modal.password-reset-modal block variant="outline-dark">
        <font-awesome-icon :icon="['fas', 'question']" /> Zabudnuté heslo
      </b-button>
    </b-col>
    <PasswordResetModalForm />
  </b-row>
</template>

<script>
import analytics from "../../mixins/analytics";
import apiUsers from "../../api/users";
import GoogleButton from "./utils/GoogleButton.vue";
import LoadingSpinner from "../utils/LoadingSpinner.vue";
import PasswordResetModalForm from "./PasswordResetModalForm.vue";
import schema from "./schema";

export default {
  name: "LoginForm",
  components: {
    GoogleButton,
    LoadingSpinner,
    PasswordResetModalForm,
  },
  mixins: [apiUsers, analytics],
  props: {},
  data() {
    return {
      formSchema: schema.applyBlurHandlers(this, [
        schema.req({ ...schema.email, autocomplete: "on" }, false),
        schema.req({ ...schema.password, autocomplete: "on" }, false),
      ]),
      formData: {
        email: "",
        password: "",
      },
      formErrors: [],
      ongoingLogin: false,
      ongoingGoogleLogin: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    loginEmail() {
      this.ongoingLogin = true;
      this.apiLoginEmail(this.formData.email, this.formData.password)
        .then((response) => {
          this.$root.setAuthToken(response.key);
          this.$emit("login-modal-close");
          this.$root.successToast("Prihlásenie úspešné!");
          this.trackLogin("email");
        })
        .catch((e) => {
          const data = e.response.data;
          if (this.checkEmailVerification(data)) return;
          this.formErrors = data.non_field_errors;
          this.$root.dangerToast(
            "Prihlásenie neúspešné. Skontroluj správnosť údajov!",
          );
        })
        .finally(() => (this.ongoingLogin = false));
    },
    loginGoogle() {
      this.ongoingGoogleLogin = true;
      this.$googleClient
        .loginPopup()
        .then((auth_code) => this.apiLoginGoogle(auth_code))
        .then((response) => {
          this.$root.setAuthToken(response.key);
          this.$emit("login-modal-close");
          this.$root.successToast("Prihlásenie úspešné!");
          this.trackLogin("Google");
        })
        .catch((e) => {
          if (!e.response) {
            return this.$root.dangerToast(
              "Chyba počas voľby účtu Google. Skús prihlasovanie zopakovať. " +
                "Ak by chyba pretrvávala, napíš nám na it@p-mat.sk.",
              8000,
            );
          }

          const data = e.response.data;
          if (this.checkEmailVerification(data)) return;

          // TODO proper translations at backend
          if (
            data[0] ===
            "This address was used for registration using email. Please log in instead!"
          ) {
            return this.$root.dangerToast(
              "Na registráciu si použil/-a email namiesto účtu Google. " +
                "Prihlás sa pomocou emailu a hesla. Ak si heslo zabudol/-la, " +
                'stlač "zabudnuté heslo".',
              8000,
            );
          }

          if (
            data[0] ===
            "This account does not exist yet. Please register first!"
          ) {
            this.$emit("switch-to-register");
            return this.$root.dangerToast(
              "S týmto účtom Google si sa zatiaľ nezaregistroval/-a. " +
                "Pred prihlásením sa najprv zaregistruj.",
              8000,
            );
          }

          this.$root.dangerToast(
            "Chyba počas prihlasovania pomocou Google. Zaregistroval/-a si sa pomocou Google?",
            8000,
          );
          this.$emit("switch-to-register");
        })
        .finally(() => (this.ongoingGoogleLogin = false));
    },
    checkEmailVerification(data) {
      /**
       * TODO it would be nicer to determine the fact that this is an unverified email error
       * based on something different than a string. It is however questionable what should it be
       * and how to implement it.
       */
      if (
        data.non_field_errors &&
        data.non_field_errors[0] === "E-mail is not verified."
      ) {
        // We hide the toast created by default during an error response from the API.
        this.$root.hideToast();
        this.$root
          .warningModalOk(
            "Tvoj účet ešte nemá overenú emailovú adresu.",
            "Skontroluj svoj email a klikni na odkaz ktorý sme ti zaslali. Ak ti nič neprišlo, " +
              "presmerujeme ťa na stránku, kde si môžeš nechať overovací email zaslať znova.",
          )
          .then(() => {
            this.$emit("login-modal-close");
            this.$router.push({
              name: "email-verification",
              query: { email: this.formData.email },
            });
          });
        return true;
      }
      return false;
    },
  },
};
</script>
