<template>
  <AnimatedIcon :size="size">
    <circle cx="0" cy="0" r="50" class="animated-circle info-circle" />
    <path d="M 0 22 L 0 -5" class="animated-path info-line" />
    <circle cx="0" cy="-20" r="5" class="animated-dot info-dot" />
  </AnimatedIcon>
</template>

<script>
import AnimatedIcon from "./AnimatedIcon.vue";

export default {
  name: "AnimatedIconInfo",
  components: {
    AnimatedIcon,
  },
  mixins: [],
  props: {
    size: {
      type: String,
      default: "sm",
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.info-circle {
  stroke: var(--info);
  transform: scaleX(-1) rotate(90deg);
}

.info-line {
  stroke: var(--info);
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
}

.info-dot {
  stroke: var(--info);
  fill: var(--info);
}
</style>
