export default (key) => {
  if (!key) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("data-website-id", key);
  script.async = true;
  script.defer = true;
  script.src = "https://stats.p-mat.sk/umami.js";

  document.getElementsByTagName("body")[0].appendChild(script);
};
