<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Obnova hesla</h1>
        <p>
          Požiadal/-a si o obnova zabudnutého hesla na stránkach
          Pikomatu/Pikofyzu. Vyplnením hesla nižšie a odoslaním formulára bude
          tvoje heslo zmenené.
        </p>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col md="6" lg="4">
        <FormulateForm
          v-model="formData"
          name="password-set"
          :schema="formSchema"
          :errors="fieldErrors"
          @change="(e) => (fieldErrors[e.target.name] = [])"
          @submit="changePassword"
        >
          <div class="text-center mt-3">
            <b-button
              size="lg"
              type="submit"
              variant="primary"
              :disabled="changingPassword"
              @click.prevent="$formulate.submit('password-set')"
            >
              <LoadingSpinner :is-loaded="!changingPassword">
                <font-awesome-icon :icon="['fas', 'redo']" />
                Zmeniť heslo
              </LoadingSpinner>
            </b-button>
          </div>
        </FormulateForm>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import apiUsers from "../../api/users";
import LoadingSpinner from "../utils/LoadingSpinner.vue";
import schema from "./schema";

export default {
  name: "PasswordSetForm",
  components: {
    LoadingSpinner,
  },
  mixins: [apiUsers],
  props: {
    token: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formSchema: schema.applyBlurHandlers(this, [
        schema.req(
          { ...schema.password, validation: "min:8,length", "@change": true },
          false,
        ),
        schema.req(schema.passwordConfirm, false),
      ]),
      formData: {},
      fieldErrors: {},
      changingPassword: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePassword() {
      this.changingPassword = true;
      return this.apiResetPasswordConfirm(
        this.uid,
        this.token,
        this.formData.password,
      )
        .then(() => {
          this.$root
            .successModalOk(
              "Obnova hesla úspešná!",
              "S novým heslom sa teraz môžeš prihlásiť.",
            )
            .then(() => this.$router.push("/"));
        })
        .catch(({ response }) => {
          this.fieldErrors = { password: response.data.new_password2 || [] };
          this.$root.dangerModalOk(
            "Heslo sa nepodarilo obnoviť!",
            "Odkaz na obnovu nebol platný alebo zadané heslo nespĺňa minimálne požiadavky.",
          );
        })
        .finally(() => (this.changingPassword = false));
    },
  },
};
</script>
